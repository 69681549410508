import ALIPAY from '@assets/images/payment-methods/ALIPAY.png';
import AMERICAN_EXPRESS from '@assets/images/payment-methods/AMERICAN_EXPRESS.png';
import BANKPAY from '@assets/images/payment-methods/BANKPAY.png';
import BANK_TRANSFER from '@assets/images/payment-methods/BANK_TRANSFER.png';
import BANK_TRANSFER_H2H from '@assets/images/payment-methods/BANK_TRANSFER_H2H.png';
import BEP20 from '@assets/images/payment-methods/BEP20.png';
import BKASH from '@assets/images/payment-methods/BKASH.png';
import CASH from '@assets/images/payment-methods/CASH.png';
import COD from '@assets/images/payment-methods/COD.png';
import CREDIT_CARD from '@assets/images/payment-methods/CREDIT_CARD.png';
import CUP_WALLET from '@assets/images/payment-methods/CUP_WALLET.png';
import DEBIT_CARD from '@assets/images/payment-methods/DEBIT_CARD.png';
import DINERS_CLUB from '@assets/images/payment-methods/DINERS_CLUB.png';
import DISCOVER from '@assets/images/payment-methods/DISCOVER.png';
import DUITNOW from '@assets/images/payment-methods/DUITNOW.png';
import EASY_PAISA from '@assets/images/payment-methods/EASY_PAISA.png';
import ERC20 from '@assets/images/payment-methods/ERC20.png';
import EXPRESS_BANK_TRANSFER from '@assets/images/payment-methods/EXPRESS_BANK_TRANSFER.png';
import FPX from '@assets/images/payment-methods/FPX.png';
import G_PAY from '@assets/images/payment-methods/G_PAY.png';
import JAZZ_CASH from '@assets/images/payment-methods/JAZZ_CASH.png';
import JCB from '@assets/images/payment-methods/JCB.png';
import MASTERCARD from '@assets/images/payment-methods/MASTERCARD.png';
import MOBILE_MONEY from '@assets/images/payment-methods/MOBILE_MONEY.png';
import MOMOPAY from '@assets/images/payment-methods/MOMOPAY.png';
import NAGAD from '@assets/images/payment-methods/NAGAD.png';
import NAYA_PAY from '@assets/images/payment-methods/NAYA_PAY.png';
import NETBANKING from '@assets/images/payment-methods/NETBANKING.png';
import ONLINE from '@assets/images/payment-methods/ONLINE.png';
import OPEN_BANKING from '@assets/images/payment-methods/OPEN_BANKING.png';
import PAPARA from '@assets/images/payment-methods/PAPARA.png';
// import PAROLA from '@assets/images/payment-methods/PAROLA.png';
import PAY_ID from '@assets/images/payment-methods/PAY_ID.png';
import PAY_TM from '@assets/images/payment-methods/PAY_TM.png';
import PAYNOW from '@assets/images/payment-methods/PAYNOW.png';
import PHONE_PE from '@assets/images/payment-methods/PHONE_PE.png';
import PIX from '@assets/images/payment-methods/PIX.png';
import QR_CODE from '@assets/images/payment-methods/QR_CODE.png';
import QRIS from '@assets/images/payment-methods/QRIS.png';
import QUICKPAY from '@assets/images/payment-methods/QUICKPAY.png';
import RAAST from '@assets/images/payment-methods/RAAST.png';
import ROCKET from '@assets/images/payment-methods/ROCKET.png';
import S2S_UPI from '@assets/images/payment-methods/S2S_UPI.png';
import TRC20 from '@assets/images/payment-methods/TRC20.png';
import TRUEMONEY from '@assets/images/payment-methods/TRUEMONEY.png';
import UNIONPAY_INTERNATIONAL from '@assets/images/payment-methods/UNIONPAY_INTERNATIONAL.png';
import UNIONPAY_INTERNATIONAL_EURO from '@assets/images/payment-methods/UNIONPAY_INTERNATIONAL.png';
import UNIONPAY from '@assets/images/payment-methods/UNIONPAY.png';
import UPAY from '@assets/images/payment-methods/UPAY.png';
import UPI from '@assets/images/payment-methods/UPI.png';
import UPI_H2H from '@assets/images/payment-methods/UPI_H2H.png';
import VISA from '@assets/images/payment-methods/VISA.png';
import WALLETS from '@assets/images/payment-methods/WALLETS.png';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ALIPAY,
  ALIPAY_SCAN: ALIPAY,
  AMERICAN_EXPRESS,
  BANKPAY,
  BANK_TRANSFER,
  BANK_TRANSFER_H2H,
  BEP20,
  BKASH,
  CASH,
  COD,
  CREDIT_CARD,
  CUP: CUP_WALLET,
  DEBIT_CARD,
  DINERS_CLUB,
  DISCOVER,
  DUITNOW,
  EASY_PAISA,
  ERC20,
  EXPRESS_BANK_TRANSFER,
  FPX,
  G_PAY,
  JAZZ_CASH,
  JCB,
  MASTERCARD,
  MOBILE_MONEY,
  MOMOPAY,
  NAGAD,
  NAYA_PAY,
  NB: NETBANKING,
  NB_CASHIER: NETBANKING,
  NETBANKING,
  ONLINE,
  OPEN_BANKING,
  PAPARA,
  // PAROLA,
  PAY_ID,
  PAY_TM,
  PAYNOW,
  PHONE_PE,
  PIX,
  QR_CODE,
  QR_CODE_THB: NETBANKING,
  QRIS,
  QUICKPAY,
  RAAST,
  ROCKET,
  S2S_UPI,
  TRC20,
  TRUEMONEY,
  UNIONPAY,
  UNIONPAY_INTERNATIONAL,
  UNIONPAY_INTERNATIONAL_EURO,
  UPAY,
  UPI,
  UPI_H2H,
  VIRTUAL_ACCOUNT: NETBANKING,
  VIRTUAL_ACCOUNT_IDR: NETBANKING,
  VISA,
  WALLETS
};