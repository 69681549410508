import React, { Component } from 'react';
import { Column, Row } from 'simple-flexbox';
import { Panel, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Datetime from 'react-datetime';

import Alert from "@sm/components/custom/Alert";
import CustomButton from "@sm/components/custom/Button";
import PageHeader from "@sm/components/PageHeader";
import Dropdowns from '@modules/apiData/dropdowns';
import Form from "@sm/core/Form";
import Multiselect from "@sm/components/custom/customMultiselect";
import Select from "@sm/components/custom/Select";
import Spinner from '@sm/components/Spinner';

import '@assets/css/referrals.css';
import Calendar from '@assets/images/calendar.png';
import PendingReferralsIcon from '@assets/images/user-management.png';

import { crudActions } from "@sm/services/crudActions";
import { getFormattedDate } from "@sm/utils/utils";
import { FETCH_PERMISSIONS } from '@sm/actions/types';
const store =  require('@sm/reducers/index');

class PendingReferrals extends Component {
  state = {
    controls: {
      timePeriod: {
        value:'thisMonth'
      },
      from: this.getPeriod(1).from,
      to: this.getPeriod(1).to
    },
    dateFieldDisabled: true,
    dropdowns: {
      periods: Dropdowns.periods
    },
    timeStamp: {
      fromDateTimeStamp: this.getPeriod(2).from,
      toDateTimeStamp: this.getPeriod(2).to
    },
    lastUpdate: Datetime.moment().format('HH:mm - DD. MMM YYYY'),

    filterForm: new Form({
      productTypes: [],
      displayMode: "",
      sortBy: "",
      email: "",
      telegram: "",
      statuses: []
    }, [{
      name: "productTypes",
      type: "isArray",
      rules: {
        required: true,
        min: 1
      }
    }, {
      name: "displayMode",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "statuses",
      type: "isArray",
      rules: {
        required: true,
        min: 1
      }
    }, {
      name: "sortBy",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "email",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "telegram",
      type: "isString",
      rules: {
        required: false
      }
    }]),

    isLoading: false,
    isLookupsLoading: true,
    showFilteredReferrals: false,
    filterClicked: false,
    columns: [{
      value: "approvalRequestType",
      label: "Product",
      mapItems: "productTypes"
    }, {
      value: "contactPerson",
      label: "Contact Person"
    }, {
      value: "brandName",
      label: "Brand Name",
      noValueMessage: "No brand."
    }, {
      value: "email",
      label: "Email"
    }, {
      value: "phone",
      label: "Phone",
      noValueMessage: "No phone."
    }, {
      value: "skype",
      label: "Skype",
      noValueMessage: "No skype."
    }, {
      value: "telegram",
      label: "Telegram",
      noValueMessage: "No telegram."
    }],
    pendingReferrals: [],

    displayMode: [],
    productTypes: [],
    requestStatuses: [],
    sortBy: [],

    showAlert: false,
    alertType: "success",
    alertMessage: "",

    roleId: "",
    access: []
  };

  subscribeFunction = null;

  componentDidMount() {
    const storeState = store.default.getState().authReducer;
    if (storeState.access) {
      const roleId = storeState.roleId;
      this.setState({
        access: storeState.access,
        roleId: roleId
      });
    }

    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().authReducer;

      if (state.userUpdate === FETCH_PERMISSIONS) {
        const roleId = storeState.roleId;
        this.setState({
          access: state.access,
          roleId: roleId
        });
      }
    });

    const { filterForm } = this.state;
    crudActions.get(`v1/approval/requests/lookups`).then(
      (lookups) => {
        if (lookups) {
          const sortArray = (arr) => {
            arr.sort((elemA, elemB) => elemA.label.localeCompare(elemB.label));

            return arr;
          };
          const sortBy = sortArray(lookups.sortBy);
          const displayMode = sortArray(lookups.displayMode);
          const productTypes = lookups.productTypes.filter(elem => elem.value !== "AGENT");
          const requestStatuses = lookups.requestStatuses;
          this.setState({
            sortBy,
            displayMode,
            productTypes,
            requestStatuses,
            filterForm: Object.assign(filterForm, {
              sortBy: sortBy[0].value,
              displayMode: displayMode[0].value,
              productTypes: productTypes.map(elem => elem.value),
              statuses: requestStatuses.map(elem => elem.value)
            }),
            isLookupsLoading: false
          });
        }
      }
    );
  };

  componentWillUnmount() {
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  };

  isDateValid = (current, state) => {
    const fromDate = this.state.timeStamp.fromDateTimeStamp;
    const toDate = this.state.timeStamp.toDateTimeStamp;
    const yesterday = Datetime.moment().utc().subtract(1, 'days');

    if (state === 'fromDateTimeStamp') {
      return current.isBefore(!fromDate ? yesterday : toDate);
    }

    return current.isAfter(!toDate ? yesterday : Datetime.moment(fromDate).subtract(1, 'days'));
  };

  getPeriod(periodType) {
    const moment = Datetime.moment;

    switch (periodType) {
    case 1: // yesterday
      return {
        from: moment().utc().subtract(1, 'day'),
        to: moment().utc().subtract(1, 'day')
      };
    case 2: // this month
      return {
        from: moment().utc().year(moment().utc().year()).month(moment().utc().month()).date(1),
        to: moment().utc()
      };
    case 3: // last month
      return {
        from: moment().utc().year(moment().utc().year()).month(moment().utc().month() - 1).date(1),
        to: moment().utc().year(moment().utc().year()).month(moment().utc().month() - 1)
          .date(moment().utc().month(moment().utc().month() - 1).daysInMonth())
      };
    case 4: // this year
      return {
        from: moment().utc().year(moment().utc().year()).month(0).date(1),
        to: moment().utc()
      };
    case 5: // last year
      return {
        from: moment().utc().year(moment().utc().year() - 1).month(0).date(1),
        to: moment().utc().year(moment().utc().year() - 1).month(11).date(31)
      };
    case 0: // today
    default: // custom period
      return {
        from: moment().utc(),
        to: moment().utc()
      };
    }
  };

  onSelectPeriodChange = (value, id) => {
    const timeStamp = {...this.state.timeStamp};
    const controls = {...this.state.controls};
    value = value === null ? {
      id: 0,
      value: 'today',
      label: 'Today'
    } : value;
    const dateFieldDisabled = value.id <= 5; // depends on any predefined period (today, yesterday etc.)
    const period = this.getPeriod(value.id);

    controls[id] = value;
    timeStamp.fromDateTimeStamp = period.from;
    timeStamp.toDateTimeStamp = period.to;
    controls.from = getFormattedDate(timeStamp.fromDateTimeStamp._d);
    controls.to = getFormattedDate(timeStamp.toDateTimeStamp._d);

    //if this month
    if (value.id === 2) {
      this.thisMonth = true;
    } else {
      this.thisMonth = false;
    }

    this.setState({
      controls: controls,
      dateFieldDisabled: dateFieldDisabled,
      timeStamp: timeStamp
    });
  };

  handleDateChange = (event, state, fieldState) => {
    const controls = {...this.state.controls};
    const timeStamp = {...this.state.timeStamp};

    timeStamp[state] = event;

    const day = event._d.getDate() > 9 ? event._d.getDate() : '0' + event._d.getDate();
    const month = event._d.getMonth() + 1 > 9 ? event._d.getMonth() + 1 : '0' + (event._d.getMonth() + 1);
    controls[fieldState] = `${day}.${month}.${event._d.getFullYear()}`;

    this.setState({controls: controls, timeStamp: timeStamp, isSubmitting: false});
  };

  onValueChange = (event, field) => {
    const { filterForm, filterClicked } = this.state;
    if (!event) {
      filterForm[field] = event;
    } else if (event.target) {
      filterForm[field] = event.target.value;
    } else if (event.value) {
      filterForm[field] = event.value;
    } else {
      filterForm[field] = event.map(elem => elem.value);
    }

    if (field === "company") {
      this.loadCategories(filterForm[field]);
    }

    if (filterClicked) {
      filterForm.isFormValid();
    }

    this.setState({
      filterForm
    });
  };

  mapSelectedItems = (propertyName, lookupsName) => {
    const field = this.state.filterForm[propertyName];
    const lookup = this.state[lookupsName];

    return field.map(elem => {
      return lookup.find(lookupElem => elem === lookupElem.value);
    });
  };

  onConfirm = () => {
    this.setState({
      showAlert: false,
      alertType: "success",
      alertMessage: ""
    });
  };

  onFilterChange = () => {
    const { filterForm, timeStamp } = this.state;
    const isValid = filterForm.isFormValid();

    this.setState({
      isLoading: isValid,
      filterForm: filterForm,
      filterClicked: true,
      showFilteredReferrals: isValid
    });

    const fromDateTimeStamp = Datetime.moment(timeStamp.fromDateTimeStamp).utc().format("DD.MM.YYYY");
    const toDateTimeStamp = Datetime.moment(timeStamp.toDateTimeStamp).utc().format("DD.MM.YYYY");

    if (isValid) {
      const data = filterForm.data();
      crudActions.post(`v1/approval/requests/find`, Object.assign(data, {
        fromDate: fromDateTimeStamp,
        toDate: toDateTimeStamp
      })).then(
        (pendingReferrals) => {
          if (pendingReferrals) {
            this.setState({
              pendingReferrals: pendingReferrals,
              isLoading: false
            });
          }
        }
      ).catch(
        (err) => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message,
              isLoading: false
            });
          }
        }
      );
    }
  };

  mapItem = (fieldValue, lookupName) => {
    const lookup = this.state[lookupName];
    const foundField = lookup.find(elem => elem.value === fieldValue);

    return foundField ? foundField.label : "";
  };

  onChangeStatus = (referralId, event) => {
    const { pendingReferrals } = this.state;
    const status = event.value;
    const referral = pendingReferrals.find(elem => elem.id === referralId);

    if (!referral) {
      return;
    }

    crudActions.put('v1/approval/requests/status', {
      id: referralId,
      status: status
    }).then(
      () => {
        referral.approvalRequestStatus = status;
        this.setState({
          pendingReferrals: pendingReferrals,
          showAlert: true,
          alertType: "success",
          alertMessage: "Status successfully changed."
        });
      }
    ).catch(
      err => {
        if (err && err.message) {
          this.setState({
            showAlert: true,
            alertType: "error",
            alertMessage: err.message
          });
        }
      }
    );
  };

  onSignUpLinkClick = (hasAccess) => {
    this.setState({
      showAlert: true,
      alertType: "error",
      alertMessage: !hasAccess ? "You don't have access for creating this product." :
        "Please, approve this request before creating the product."
    });
  };

  checkPageAccess = (permissionName) => {
    const { access } = this.state;
    const foundPermission = access.find(elem => elem.permission === permissionName);
    if (!foundPermission) {
      return false;
    }

    return foundPermission.state;
  };

  checkProductAccess = (approvalRequestType) => {
    const { access, roleId } = this.state;
    const isMasterTech = roleId === "MASTER_TECH";
    const merchants = access.find(elem => elem.permission === "MERCHANTS_EDIT");
    const whiteLabels = isMasterTech;
    const psps = isMasterTech;

    const accessMap = {
      MERCHANT: merchants && merchants.state,
      WHITE_LABEL: whiteLabels,
      PSP: psps
    };

    return accessMap[approvalRequestType];
  };

  render() {
    const { isLoading, columns, pendingReferrals, isLookupsLoading, controls, timeStamp, dateFieldDisabled,
      sortBy, requestStatuses, productTypes, displayMode, filterForm, showFilteredReferrals, showAlert,
      alertType, alertMessage } = this.state;

    const redirectMap = {
      MERCHANT: "/add-merchant",
      WHITE_LABEL: "/add-white-label",
      PSP: "/add-provider"
    };

    return (
      <Row flexGrow={ 1 } className="module referrals" vertical='start'>
        <Column flexGrow={ 1 }>
          <PageHeader
            title={ 'Pending Referrals' }
            img={ PendingReferralsIcon }
          />
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              <Panel>
                <Panel.Heading>
                  <Panel.Title>
                    PENDING REFERRALS
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  { isLookupsLoading ? <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                    <Spinner smallContainer={ true } />
                  </div> : <div className="panel-content" style={ {overflow: 'unset'} }>
                    <Row flexGrow={ 1 } alignSelf='start' horizontal='start' wrap={ true } vertical='start'>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Time Period </label>
                          <Select id="timePeriod"
                            name="timePeriod"
                            value={ controls.timePeriod.value || '' }
                            required={ true }
                            clearable={ false }
                            onChange={ (value) => this.onSelectPeriodChange(value, 'timePeriod') }
                            options={ this.state.dropdowns.periods }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label>From Date</label>
                          <img src={ Calendar } className="calendar-svg" alt=""/>
                          <Datetime type="text"
                            timeFormat={ false }
                            className="datetime-container"
                            dateFormat="DD.MM.YYYY"
                            inputProps={ {
                              disabled: dateFieldDisabled,
                              readOnly: true//user is not allowed changed the date from input
                            } }
                            utc={ true }
                            isValidDate={ (event) => this.isDateValid(event, 'fromDateTimeStamp') }
                            value={ timeStamp.fromDateTimeStamp || '' }
                            onChange={ (event) => this.handleDateChange(event, 'fromDateTimeStamp', 'from') }
                            required={ true }/>
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label>To Date</label>
                          <img src={ Calendar } className="calendar-svg" alt=""/>
                          <Datetime type="text"
                            className="datetime-container"
                            timeFormat={ false }
                            dateFormat="DD.MM.YYYY"
                            inputProps={ {
                              disabled: dateFieldDisabled,
                              readOnly: true//user is not allowed changed the date from input
                            } }
                            utc={ true }
                            isValidDate={ (event) => this.isDateValid(event, 'toDateTimeStamp') }
                            value={ timeStamp.toDateTimeStamp || '' }
                            onChange={ (event) => this.handleDateChange(event, 'toDateTimeStamp', 'to') }
                            required={ true }/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column empty-column">
                          <span/>
                        </Column>
                      </Row>
                    </Row>
                    <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Product </label>
                          <Multiselect
                            isError={ filterForm.errors.has("productTypes") }
                            selectedItems={ this.mapSelectedItems("productTypes", "productTypes") }
                            items={ productTypes }
                            type={ "productTypes" }
                            onChange={ this.onValueChange }/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Display Mode </label>
                          <Select id="displayMode"
                            name="displayMode"
                            className={ (filterForm.errors.has("displayMode") ? 'error-field' : "") }
                            value={ filterForm.displayMode || '' }
                            required={ true }
                            clearable={ false }
                            onChange={ (value) => this.onValueChange(value, 'displayMode') }
                            options={ displayMode }
                          />
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Sort By </label>
                          <Select id="sortBy"
                            name="sortBy"
                            className={ (filterForm.errors.has("sortBy") ? 'error-field' : "") }
                            value={ filterForm.sortBy || '' }
                            required={ true }
                            clearable={ false }
                            onChange={ (value) => this.onValueChange(value, 'sortBy') }
                            options={ sortBy }
                          />
                        </Column>
                        <Column flexGrow={ 1 } className="input-column">
                          <span/>
                        </Column>
                      </Row>
                    </Row>
                    <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Status </label>
                          <Multiselect
                            isError={ filterForm.errors.has("statuses") }
                            selectedItems={ this.mapSelectedItems("statuses", "requestStatuses") }
                            items={ requestStatuses }
                            type={ "statuses" }
                            onChange={ this.onValueChange }/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Search Email </label>
                          <input
                            className={ "form-control " + (filterForm.errors.has("email") ? 'error-field' : "") }
                            value={ filterForm.email || "" }
                            onChange={ (e) => this.onValueChange(e, "email") }/>
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Search Phone/Telegram </label>
                          <input
                            className={ "form-control " + (filterForm.errors.has("telegram") ? 'error-field' : "") }
                            value={ filterForm.telegram || "" }
                            onChange={ (e) => this.onValueChange(e, "telegram") }/>
                        </Column>
                        <Column flexGrow={ 1 } className="input-column">
                          <span/>
                        </Column>
                      </Row>
                    </Row>
                    <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                      <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                        <span/>
                      </Column>
                      <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                        <span/>
                      </Column>
                      <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                        <span/>
                      </Column>
                      <Column flexGrow={ 1 } className="input-column fullwidth-button">
                        <CustomButton
                          title="Search"
                          type="submit"
                          onClick={ () => this.onFilterChange() }
                        />
                      </Column>
                    </Row>
                  </div> }
                </Panel.Body>
              </Panel>
              { showFilteredReferrals && <Panel>
                <Panel.Heading>
                  <Panel.Title>
                    PENDING REFERRALS
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  { isLoading ? <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                    <Spinner smallContainer={ true } />
                  </div> : <div className="panel-content" style={ {overflow: 'unset'} }>
                    <div className="referrals-wrapper">
                      <table className="table">
                        <thead>
                          <tr>
                            {
                              columns.map(column => {
                                return <th key={ column.value }>
                                  { column.label }
                                </th>;
                              })
                            }
                            <th>
                              Sign-up URL
                            </th>
                            { this.checkPageAccess("REFERRALS_EDIT") && <th>
                              Status
                            </th> }
                          </tr>
                        </thead>
                        <tbody>
                          {
                            pendingReferrals.map(elem => {
                              return <tr key={ elem.id }>
                                {
                                  columns.map(column => {
                                    return <td key={ column.value }>
                                      { column.mapItems ? this.mapItem(elem[column.value], column.mapItems) :
                                        (elem[column.value] || column.noValueMessage) }
                                    </td>;
                                  })
                                }
                                <td>
                                  {
                                    !this.checkProductAccess(elem.approvalRequestType) || elem.approvalRequestStatus !== "APPROVED" ? <Button
                                      className="btn btn-deny"
                                      onClick={ () => this.onSignUpLinkClick(this.checkProductAccess(elem.approvalRequestType)) }>
                                      Setup
                                    </Button> : <Link
                                      className="btn btn-approve"
                                      to={ {
                                        pathname: redirectMap[elem.approvalRequestType],
                                        state: { params: { approvalRequestId: elem.id } }
                                      } }> Setup </Link>
                                  }
                                </td>
                                { this.checkPageAccess("REFERRALS_EDIT") && <td style={ { width: 150 } }>
                                  <Select id="approvalRequestStatus"
                                    name="approvalRequestStatus"
                                    value={ elem.approvalRequestStatus || '' }
                                    required={ true }
                                    clearable={ false }
                                    isSearchable={ false }
                                    disabled={ elem.approvalRequestStatus === "APPROVED" }
                                    onChange={ (value) => this.onChangeStatus(elem.id, value) }
                                    options={ requestStatuses }
                                  />
                                </td> }
                              </tr>;
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  </div> }
                </Panel.Body>
              </Panel> }
            </Column>
          </Row>
        </Column>

        {showAlert && (
          <Alert
            show={ showAlert }
            title={ alertType }
            type={ alertType }
            text={ alertMessage }
            confirmButtonColor="#187EED"
            onConfirm={ this.onConfirm }
          />
        )}

      </Row>
    );
  }
}

export default PendingReferrals;
