import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Column } from "simple-flexbox";

import { menuList } from '@sm/utils/menuList';
import { FETCH_PERMISSIONS } from "@sm/actions/types";
const store = require("@sm/reducers/index");

class SideBar extends Component {
  state = {
    menu: menuList,

    access: [],
    roleId: "",
  };

  subscribeFunction = null;

  componentDidMount() {
    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().authReducer;

      if (state.userUpdate === FETCH_PERMISSIONS) {
        this.setState({
          access: state.access,
          roleId: state.roleId,
        });
      }
    });
  }

  componentWillUnmount() {
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  }

  checkPageAccessCategory = (categoryName) => {
    const { access, roleId } = this.state;
    const freeAccessed = ["MY_ACCOUNT", "API"];
    if (freeAccessed.some((elem) => elem === categoryName)) {
      return true;
    } else if (
      (categoryName === "COMPANIES" || categoryName === "PSPS") &&
      roleId === "MASTER_TECH"
    ) {
      return roleId === "MASTER_TECH";
    } else if (
      categoryName === "PSPS" &&
      access.find(
        (elem) => elem.permission === "PSP_VIEW" && elem.state === true
      )
    ) {
      return roleId === "MASTER_ADMIN" || roleId === "MASTER_USER";
    }

    const foundPermissions = access.filter(
      (elem) => elem.category === categoryName
    );
    return foundPermissions.some((elem) => elem.state);
  };

  checkPageAccess = (permissionName) => {
    const { access } = this.state;
    const foundPermission = access.find(
      (elem) => elem.permission === permissionName
    );
    if (!foundPermission) {
      return false;
    }

    return foundPermission.state;
  };

  displayMenuItem = (menu) => {
    const { access, roleId } = this.state;
    if (
      menu.submenu.find((elem) => elem.access === "MY_ACCOUNT") ||
      (menu.submenu.find((elem) => elem.access === "API") && roleId !== "PROVIDER_ADMIN")
    ) {
      return true;
    }

    const foundItems = access.filter((permission) =>
      menu.submenu.some(
        (elem) =>
          permission[elem.permission ? "permission" : "category"] ===
          elem.access
      )
    );
    return foundItems.some((elem) => elem.state);
  };

  sortMenu = (menu) => {
    menu.sort((elemA, elemB) => {
      if (elemA.name < elemB.name) return -1;
      if (elemA.name > elemB.name) return 1;
      return 0;
    });
    return menu;
  };

  render() {
    return (
      <div className="sidebar">
        <div className="list-menus">
          {this.state.menu.map((menu, i) => {
            return (
              this.displayMenuItem(menu) && (
                <Column key={ i } className="menublock">
                  <Column className="header">{menu.header}</Column>
                  {this.sortMenu(menu.submenu).map((item, j) => {
                    return (
                      (item.permission
                        ? this.checkPageAccess(item.access)
                        : this.checkPageAccessCategory(item.access)) && (
                        <NavLink to={ item.url } key={ j } className="menuItem">
                          {" "}
                          {item.name}{" "}
                        </NavLink>
                      )
                    );
                  })}
                </Column>
              )
            );
          })}
        </div>
      </div>
    );
  }
}

export default SideBar;
