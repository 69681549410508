import Datetime from 'react-datetime';
var randomstring = require("randomstring");

export function getFormattedDate(date) {
  const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
  const month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);

  return `${day}.${month}.${date.getFullYear()}`;
};

export function toFormattedNumber(value) {
  return Number(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

export function convertUTCToLocalDate(date) {
  if (!date) {
    return date;
  }

  date = Datetime.moment(date);
  
  date = new Date(
    date.year(), date.month(), date.date(),
    date.hour(), date.minute(), date.second()
  );

  return date;
};

export function convertLocalToUTCDate(date) {
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(
    date.getFullYear(), date.getMonth(), date.getDate(),
    date.getHours(), date.getMinutes()
  );
  return date;
};

export function generatePassword() {
  const passwordLength = 12;
  let choiceArray = [0, 1, 2, 3];
  let newPassword = "";

  for (let i = 0; i < passwordLength; i++) {
    const choice = choiceArray[Math.floor(Math.random()*choiceArray.length)];

    if (choice === 0) {
      newPassword += randomLower();
    } else if (choice === 1) {
      newPassword += randomUpper();
    } else if (choice === 2) {
      newPassword += randomSymbol();
    } else if (choice === 3) {
      newPassword += random(0, 9);
    } else {
      i--;
    }
    choiceArray = choiceArray.filter(e => e !== choice);
    if (!choiceArray.length) {
      choiceArray = [0, 1, 2, 3];
    }
  }

  return newPassword;
};

const random = (min = 0, max = 1) => {
  return Math.floor(Math.random() * (max + 1 - min) + min);
};

const randomLower = () => {
  const lowerCase = "abcdefghijklmnopqrstuvwxyz";
  return lowerCase[random(0, lowerCase.length - 1)];
};

const randomUpper = () => {
  const upperCase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  return upperCase[random(0, upperCase.length - 1)];
};

const randomSymbol = () => {
  const symbols = "*&!$%@";
  return symbols[random(0, symbols.length - 1)];
};

export function addLogItem(logs, type, valueBefore, valueAfter, field, id, info) {
  const isLogGroupPresent = logs.find(e => e.id === id.toString() && e.type === type);
  // if log type is already there
  if (isLogGroupPresent) {
    logs.forEach((logGroup, index) => {
      const isParameterPresent = logGroup.parameters.some(p => p.fieldName === field);
      if (isParameterPresent) {
        logGroup.parameters.forEach((log, i) => {
          if (log.fieldName === field) {
            if (log.valueBefore !== valueAfter && log.fieldName === field) {
              log = Object.assign(log, { valueAfter });
            } else {
              logGroup.parameters.splice(i, 1);
            }
          }
        });
      } else if (logGroup.id === id.toString() && logGroup.type === type) {
        logGroup.parameters.push({
          fieldName: field,
          valueBefore,
          valueAfter
        });
      }
    });
    
  } else {
    logs.push({
      id: id.toString(),
      type,
      info,
      parameters: [{
        valueBefore,
        valueAfter,
        fieldName: field
      }]
    });
  }

  return logs.filter(item => item.parameters.length !== 0);
};

export function generateApiKey(length) {
  return randomstring.generate({
    length: length,
    charset: "alphanumeric"
  });
};

export function sortItems(items) {
  return items.sort((elemA, elemB) => elemA.label.localeCompare(elemB.label));
};
